/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.3/dist/jquery.min.js
 * - /npm/popper.js@1.16.0/dist/umd/popper.min.js
 * - /npm/bootstrap@4.4.1/dist/js/bootstrap.min.js
 * - /npm/bootstrap-select@1.13.9/dist/js/bootstrap-select.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
